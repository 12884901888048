'use strict';

const mixpanel = require('mixpanel-browser');
const TRACK_EVENTS = true;
window.mixpanel = mixpanel;

const TOKEN = globalVars.MIXPANEL_TOKEN;
const DEBUG = globalVars.MIXPANEL_DEBUG;
const USER_EVENTS_TRACKING_TOGGLE = globalVars.USER_EVENTS_TRACKING_TOGGLE;
const DEFAULT_CONFIG = {
    debug: DEBUG,
    api_host: '/api/tracking',
    track: TRACK_EVENTS,
    batch_requests: true,
    batch_size: 50,
    batch_flush_interval_ms: 5000,
    batch_request_timeout_ms: 90000,
    batch_autostart: true
};

module.exports = {
    events_track_type: {
        B2B_SOCIALS: 'b2b_socials',
        B2B_SETTINGS: 'b2b_settings',
        B2B_PROPERTY: 'b2b_property',
    },
    source_type: {
        APP: 'gestionale',
        EMAIL: 'email',
        PUSH: 'push',
    },
    track: function(event, payload) {
        if (!USER_EVENTS_TRACKING_TOGGLE || !DEFAULT_CONFIG.track) {
            return;
        }

        mixpanel.track(event, payload);
    },
    init: function(config) {
        mixpanel.init(TOKEN, { ...DEFAULT_CONFIG, ...config });
    },
};
