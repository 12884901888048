const gtxConstants = require('@getrix/common/js/gtx-constants');
const gtxLoggedUser = require('@getrix/common/js/gtx-logged-user');
const $ = require('jquery');
const AskHelpView = require('@getrix/common/js/ask-help');
const gtm = require('@getrix/common/js/gtm');
const beamer = require('@getrix/common/js/beamer');
const appcues = require('@getrix/common/js/appcues');
const mixpanel = require('lib/mixpanel');
const { GxNavigationBus } = require('lib/gx-navigation-bus');
const Sentry = require('@sentry/browser');
const salesforceOfflineFormEnabled = gtxConstants('SALESFORCE_OFFLINE_FORM_ENABLED') === 1;

require('@getrix/common/js/main');
require('@getrix/common/js/xhr-wrapper');
require('@getrix/common/js/fetch-wrapper');

// eslint-disable-next-line no-undef
if (globalVars.SENTRY_ENABLED) {
    Sentry.init({
        // eslint-disable-next-line no-undef
        dsn: SENTRY_DSN,
        debug: true,
        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        // eslint-disable-next-line no-undef
        release: SENTRY_VERSION,
        // eslint-disable-next-line no-undef
        environment: globalVars.SENTRY_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

const SWITCH_TO_MENU_VERSION = GxNavigationBus.createEventType('switch-menu-version', 'out');

const checkIsLoggedPage =
    gtxLoggedUser('idAgente') &&
    window.location.href.indexOf('/login/a2f') === -1 &&
    window.location.href.indexOf('/check-status/tos') === -1;

function mailXhr(url, data) {
    return $.ajax({
        url: url,
        type: 'POST',
        dataType: 'json',
        data: data,
    });
}

let askHelp = new AskHelpView({
    submitAction: mailXhr,
    external: salesforceOfflineFormEnabled ? 'salesforce' : null,
});

askHelp.initialize($('.gtx-ask-help'));

gtm.loadGTM({
    // eslint-disable-next-line no-undef
    id: globalVars.GTM_ID,
});

if (gtxConstants('APPCUES_ENABLED') === '1' && checkIsLoggedPage) {
    appcues.appCuesInit();
}

if (gtxConstants('BEAMER_NEWS') === '1' && checkIsLoggedPage) {
    beamer.beamerInit();
}

// Identify univocally a user into Dynatrace RUMS
window.addEventListener('load', () => {
    if (!checkIsLoggedPage || gtxConstants('DYNATRACE_ENABLED') === 0 || typeof window.dtrum === 'undefined') {
        return;
    }

    window.dtrum.identifyUser(gtxLoggedUser('idAgente'));
});
  
// Menu switch event
mixpanel.init();
GxNavigationBus.addListener(SWITCH_TO_MENU_VERSION, (menuType) => {
    mixpanel.track('menu_switch_menu', {
        type: mixpanel.events_track_type.B2B_SETTINGS,
        experimental: [],
        channel: 'Desktop',
        area: 'Header',
        section: 'Header',
        switching_to_version: menuType,
    });
});
