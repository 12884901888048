type DirectionType = 'in' | 'out';
type EventActionType<T> = {
    type: string;
    payload?: T;
};

const eventTypePrefix = 'gx-navigation' as const;

/**
 *
 * @param type The name of the event
 * @param direction The direction of the event
 * @description `in` from app -> gx-navigation or `out` from gx-navigation -> app
 * @returns
 */
export const createEventType = <T extends string, K extends DirectionType>(type: T, direction: K) => {
    return `${eventTypePrefix}:${direction}:${type}` as const;
};

function dispatchEvent<T>(action: EventActionType<T>) {
    const event = new CustomEvent(action.type, { detail: action.payload });
    if (process.env.NODE_ENV === 'development') {
        console.log(
            `%c🚢 ${action.type}`,
            'color: white; font-weight: bold; background: #428CC6; border-radius: 3px; padding: 2px 4px',
            action.payload
        );
    }
    window.dispatchEvent(event);
}

/**
 *
 * @param eventType **strongly recommend** to use `createEventType` to create the event type
 * @param callback The callback function to be called when the event is fired
 * @returns void
 * @example
 * const unsubscribe = addGxNavigationListener("gx-navigation:in:open", () => { console.log("open") }) // add the listener
 * unsubscribe() // clear the listener
 */
function addListener<T = unknown>(eventType: string, callback: (_payload: T | null) => void) {
    const fn = (e: CustomEvent) => {
        callback(e.detail);
    };

    // @ts-expect-error We don't care about the type of the event
    window.addEventListener(eventType, fn);

    return () => {
        // @ts-expect-error We don't care about the type of the event
        window.removeEventListener(eventType, fn);
    };
}

export const GxNavigationBus = {
    createEventType,
    dispatchEvent,
    addListener,
};
