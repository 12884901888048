'use strict';
const Sentry = require('@sentry/browser');

if (window.gtxConstants.VHOST_URL_HOST !== location.origin) {
    Sentry.captureEvent({
        message: 'origin and VHOST_URL_HOST differs',
        extra: {
            origin: location.origin,
            VHOST_URL_HOST: window.gtxConstants.VHOST_URL_HOST,
        },
    });

    window.location.replace(window.gtxConstants.VHOST_URL_HOST);
}

let gtxConstants = require('@getrix/common/js/gtx-constants');

$('.form-account').on('submit', function () {
    let $submitButton = $(this).find('button[type=submit]');
    $submitButton.attr('disabled', 'disabled');
    $submitButton.html(
        `<span data-role="spinner" class="loader-login-button">
        <svg class="gx-icon gx-spin">
            <use xlink:href="${gtxConstants('ICONS_SVG_SPRITE_FILE_PATH')}#loader_icon"></use>
        </svg>
    </span>`
    );
});
